import { appEndpoints } from '../api-endpoints';
import axios from 'axios';

import api, { Headers } from './config/api';

import { SubscriptionsDTO } from '../types/remote-dtos/subscriptions.dto';
import { SubscriptionFlter } from '../types/local-models/subscription.models';

export async function getAllSubscriptions(
  filter: SubscriptionFlter | null,
  callback: (response: any) => void
) {
  const url = appEndpoints.prod + 'masterdata/subscriptions';
  const data = {
    operation: 'list',
    masterdata: 'subscriptions',
    payload: {
      filter: filter,
    },
  };

  try {
    axios.post(url, data, { headers: Headers }).then((response) => {
      callback(response.data);
    });
  } catch (e) {
    callback(false);
  }
}

export async function addCustomerSubscriptions(
  callback: (response: any) => void
) {
  const url = appEndpoints.prod + 'accounts/subscriptions';

  const accountID = localStorage.getItem('accountID');
  const selectedBillingCycle = localStorage.getItem('selectedBillingCycle');

  if (accountID && selectedBillingCycle) {
    const data = JSON.stringify({
      operation: 'create',
      payload: {
        accountID: accountID,
        subscriptionID: localStorage.getItem('selectedSubscriptionID'),
        attributes: {
          acctSubsPaymentMethod: localStorage.getItem('selectedPaymentMethod'),
          acctSubsBillingCycle: selectedBillingCycle,
        },
      },
    });

    try {
      axios.post(url, data, { headers: Headers }).then((response) => {
        if (response.data.data.attributes) {
          callback(response.data.data.attributes.acctSubsCheckOutURL);
        } else {
          callback(false);
        }
      });
    } catch (e) {
      console.error('error: ', e);
      callback(false);
    }
  }
}

export async function isSubscribed(callback: (response: any) => void) {
  const url = appEndpoints.prod + 'accounts/subscriptions';

  const beginUserDetails = localStorage.getItem('begin_user');

  if (beginUserDetails) {
    const user = JSON.parse(beginUserDetails);

    const data = JSON.stringify({
      operation: 'read',
      payload: {
        accountID: typeof user[0] != 'undefined' ? user[0].accountID : '',
      },
    });

    try {
      axios.post(url, data, { headers: Headers }).then((response) => {
        if (response.data.data && response.data.data.attributes) {
          callback(response.data.data.length > 0);
        } else {
          callback(false);
        }
      });
    } catch (e) {
      callback(false);
    }
  }
}

const url = 'masterdata/subscriptions';
const customerSubscriptions = 'accounts/subscriptions';

export const SubscriptionsAPI = {
  getAll: async (filter: SubscriptionFlter | null) => {
    const payload = {
      operation: 'list',
      masterdata: 'subscriptions',
      payload: {
        filter: {},
        select: [],
      },
    };

    try {
      const res = await api.post(url, payload, { headers: Headers });
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  getAllCustomerSubscriptions: async () => {
    const payload: any = {
      operation: 'list',
      screen: 'subscriptions',
      payload: {
        accountID: localStorage.getItem('accountID'), // Account ID
      },
    };

    try {
      const res = await api.post(customerSubscriptions, payload, {
        headers: Headers,
      });
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  },
};
