import React, { useState, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce';
import BeginInput from '../../components/form-input-fields/begin-input';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import style from './UserSearch.module.css';
import { getSearchResult } from '../../services/search';
import BeginButton from '../../components/begin-button/begin-button';
import clsx from 'clsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Navigate, useNavigate } from 'react-router-dom';

const UserSearch = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [contentID, setContentID] = useState('');
  const [contentType, setContentType] = useState('');
  const [searchData, setSearchData] = useState<SearchResult>({
    movies: [],
    tvseries: [],
    sports: [],
  });
  const navigate = useNavigate();
  const searchedValue = useDebounce(search, 500);
  const route = useNavigate();
  interface SearchResult {
    movies: any[];
    tvseries: any[];
    sports: any[];
  }

  useEffect(() => {
    if (searchedValue) {
      getSearchResult(searchedValue, (response) => {
        console.log('response data', response.data.data[0]);
        setSearchData(response.data.data[0]);
      });
    }
  }, [searchedValue]);

  const handleBackClick = () => {
    route(-1);
  };
  const SetDetails = (id: any, type: any, callback: () => void) => {
    setContentType(type);
    setContentID(id);
    callback();
    console.log('i am executed', id, type);
  };
  const goToDetail = (id: string, type: string) => {
    console.log('content type', type);
    console.log('content id', id);
    switch (type) {
      case 'movies':
        navigate(`/movies/${id}`);
        break;
      case 'tv-shows':
        navigate(`/tv-shows/${id}`);
        break;
      case 'sports':
        navigate(`/sports/${id}`);
        break;
      // case 'explore':
      //   navigate(`/sports/${contentID}`);
      //   break;
      default:
        console.log('Invalid content type');
    }
  };

  return (
    <main className={style.container}>
      <header>
        <h3
          className={style.heading}
          onClick={handleBackClick}
        >
          <ArrowBackIosIcon /> <span> Search</span>
        </h3>
        <form
          autoComplete="off"
          onSubmit={(event) => event.preventDefault()}
        >
          <BeginInput
            type="search"
            placeholder="Search shows, Movies..."
            name="search"
            id="search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            autoComplete="off"
          />
        </form>
      </header>
      <div className={clsx(style.card)}>
        <div>
          {Object.keys(searchData).length > 0 && (
            <section className={style.content}>
              {searchData.movies && searchData.movies.length > 0 && (
                <>
                  {searchData.movies.map((movie, index) => (
                    <div
                      key={index}
                      className={style.contentItem}
                      onClick={() => {
                        console.log('movie', movie);
                        goToDetail(movie.PK, 'movies');
                      }}
                    >
                      <LazyLoadImage
                        className={clsx(style.contentThumbnail)}
                        width={224}
                        height={317}
                        effect="blur"
                        src={movie.images?.[0]?.imagePath}
                        beforeLoad={() => {
                          setLoading(true);
                        }}
                        onLoad={() => {
                          setLoading(false);
                        }}
                        onClick={() => goToDetail(movie.SK, 'movies')}
                      />
                      {/* <div className={style.contentDetails}>
                        <h1 className={clsx(style.contentTitle)}>
                          {movie.seriesOriginalTitle}
                        </h1>

                        <ul className={clsx(style.featuredContentMeta)}>
                          <li className={clsx(style.meta)}>
                            {movie.seriesType}
                          </li>
                          <li className={clsx(style.meta)}>
                            {movie.seriesRuntime}
                          </li>
                        </ul>

                        <div className={clsx(style.contentDescription)}>
                          {movie.seriesOverview}
                        </div>

                        <div className={clsx(style.contentButton)}>
                          <BeginButton
                            className="primary"
                            datalabel="Play"
                            type="button"
                            dataiconstart="icon-play"
                            squared={true}
                          />
                          <BeginButton
                            className="link"
                            datalabel="Wishlist"
                            type="button"
                            dataiconstart="icon-favorite"
                            squared={true}
                          />

                          <BeginButton
                            className="primary"
                            datalabel="Subscribe Now"
                            to=""
                            type="button"
                            dataiconstart="icon-locked"
                            squared={true}
                            onClick={(event) => {
                              event.stopPropagation();
                              // Add logic for subscription
                            }}
                          />
                        </div>
                      </div> */}
                    </div>
                  ))}
                </>
              )}

              {searchData.tvseries && searchData.tvseries.length > 0 && (
                <>
                  {searchData.tvseries.map((series, index) => (
                    <div
                      key={index}
                      className={style.contentItem}
                      onClick={() => goToDetail(series.SK, 'tv-shows')}
                    >
                      <LazyLoadImage
                        className={clsx(style.contentThumbnail)}
                        width={224}
                        height={317}
                        effect="blur"
                        src={series.images?.[0]?.imagePath}
                        beforeLoad={() => {
                          setLoading(true);
                        }}
                        onLoad={() => {
                          setLoading(false);
                        }}
                      />
                      <div className={style.overlay}>
                        <h2 className={style.title}>
                          {series.seriesOriginalTitle}
                        </h2>
                      </div>
                      {/* <div className={style.contentDetails}>
                        <h1 className={clsx(style.contentTitle)}>
                          {series.seriesOriginalTitle}
                        </h1>

                        <ul className={clsx(style.featuredContentMeta)}>
                          <li className={clsx(style.meta)}>
                            {series.seriesType}
                          </li>
                          <li className={clsx(style.meta)}>
                            {series.seriesRuntime}
                          </li>
                        </ul>

                        <div className={clsx(style.contentDescription)}>
                          {series.seriesOverview}
                        </div>

                        <div className={clsx(style.contentButton)}>
                          <BeginButton
                            className="primary"
                            datalabel="Play"
                            type="button"
                            dataiconstart="icon-play"
                            squared={true}
                          />
                          <BeginButton
                            className="link"
                            datalabel="Wishlist"
                            type="button"
                            dataiconstart="icon-favorite"
                            squared={true}
                          />

                          <BeginButton
                            className="primary"
                            datalabel="Subscribe Now"
                            to=""
                            type="button"
                            dataiconstart="icon-locked"
                            squared={true}
                            onClick={(event) => {
                              event.stopPropagation();
                              navigate('/account/available-subscriptions');
                            }}
                          />
                        </div>
                      </div> */}
                    </div>
                  ))}
                </>
              )}

              {searchData.sports && searchData.sports.length > 0 && (
                <>
                  {searchData.sports.map((sport, index) => (
                    <div
                      key={index}
                      className={style.contentItem}
                      onClick={() => goToDetail(sport.SK, 'sports')}
                    >
                      <LazyLoadImage
                        className={clsx(style.contentThumbnail)}
                        width={224}
                        height={317}
                        effect="blur"
                        src={sport.images?.[0]?.imagePath}
                        beforeLoad={() => {
                          setLoading(true);
                        }}
                        onLoad={() => {
                          setLoading(false);
                        }}
                        onClick={() => goToDetail(sport.SK, 'sports')}
                      />
                      <div className={style.overlay}>
                        <h2 className={style.title}>
                          {sport.seriesOriginalTitle}
                        </h2>
                      </div>
                      {/* <div className={style.contentDetails}>
                        <h1 className={clsx(style.contentTitle)}>
                          {sport.seriesOriginalTitle}
                        </h1>

                        <ul className={clsx(style.featuredContentMeta)}>
                          <li className={clsx(style.meta)}>
                            {sport.seriesType}
                          </li>
                          <li className={clsx(style.meta)}>
                            {sport.seriesRuntime}
                          </li>
                        </ul>

                        <div className={clsx(style.contentDescription)}>
                          {sport.seriesOverview}
                        </div>

                        <div className={clsx(style.contentButton)}>
                          <BeginButton
                            className="primary"
                            datalabel="Play"
                            type="button"
                            dataiconstart="icon-play"
                            squared={true}
                          />
                          <BeginButton
                            className="link"
                            datalabel="Wishlist"
                            type="button"
                            dataiconstart="icon-favorite"
                            squared={true}
                          />

                          <BeginButton
                            className="primary"
                            datalabel="Subscribe Now"
                            to=""
                            type="button"
                            dataiconstart="icon-locked"
                            squared={true}
                            onClick={(event) => {
                              event.stopPropagation();
                              // Add logic for subscription
                            }}
                          />
                        </div>
                      </div> */}
                    </div>
                  ))}
                </>
              )}
            </section>
          )}
        </div>
      </div>
    </main>
  );
};

export default UserSearch;
