export enum ScreenComponents {
  'sports',
  'tv-shows',
  'movies',
  // 'explore',
}

export interface ViewsProps {
  component: ScreenComponents;
  contentType?: string;
  contentID?: string;
}
