import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import stl from './content-list.module.css';

import { ContentCardProps } from '../../types/local-models/content-card.model';
import { MoviesApi } from '../../services/content-apis/movies';
import BeginButton from '../begin-button/begin-button';

import { useNavigate, useParams } from 'react-router-dom';
import { tvShowsApi } from '../../services/content-apis/tvShows';
import { sportsApi } from '../../services/content-apis/sports';
import { wishlistApi } from '../../services/content-apis/wishlist';

const ContentFeaturedBanner: FC<ContentCardProps> = ({
  contentID,
  contentType,
  contentOriginalTitle,
  contentImages,
  featuredCount,
  setPlayerUrl,
  setPlayerTitle,
  setPlayerMediaID,
}: ContentCardProps) => {
  const [loading, setLoading] = useState(true);

  const [contentDetails, setContentDetails] = useState<any>({});

  const [wishlistBtnTxt, setWishlistBtnTxt] = useState('Add to List');
  const [wishlistBtnIcon, setWishlistBtnIcon] = useState('icon-favorite');
  const [wishlistBtnLoading, setWishlistBtnLoading] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const [hasLive, setHasLive] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [liveURL, setLiveURL] = useState('');
  const [mediaTitle, setMediaTitle] = useState('');
  const [mediaID, setMediaID] = useState('');
  const [livePaymentUrl, setLivePaymentUrl] = useState('');

  // const [playerURL, setPlayerURL] = React.useState('');
  // const [drmToken, setDRMToken] = React.useState('');
  // const [loadPlayer, setLoadPlayer] = React.useState(false);

  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    switch (contentType) {
      case 'movies':
        MoviesApi.getMovie(contentID, 'card').then((response: any) => {
          setContentDetails(response);
          if (
            typeof response != 'undefined' &&
            typeof response.streamLink != 'undefined'
          ) {
            if (response.streamLink[0].isAddedToMyList) {
              setWishlistBtnTxt('Remove from List');
              setWishlistBtnIcon('icon-favorite-filled');
            } else {
              setWishlistBtnTxt('Add to List');
              setWishlistBtnIcon('icon-favorite');
            }
          }
        });
        break;
      case 'tv-shows':
        tvShowsApi.getTVShow(contentID, 'card').then((response: any) => {
          setContentDetails(response);

          if (response.streamLink[0].isAddedToMyList) {
            setWishlistBtnTxt('Remove from List');
            setWishlistBtnIcon('icon-favorite-filled');
          } else {
            setWishlistBtnTxt('Add to List');
            setWishlistBtnIcon('icon-favorite');
          }
        });
        break;
      case 'sports':
        sportsApi.getTVShow(contentID, 'card').then((response: any) => {
          setContentDetails(response);

          const getIsLiveAndSubscribed = async () => {
            if (
              typeof response.streamLink[0] === 'undefined' ||
              typeof response.streamLink[0].seasons[0] === 'undefined'
            )
              return;
            sportsApi
              .getEpisodesBySeason(response.streamLink[0].seasons[0].seasonID)
              .then((episodesList: any) => {
                setHasLive(
                  episodesList.some((carousel: any) => {
                    return carousel.carousel === 'Live';
                  })
                );

                setIsSubscribed(
                  episodesList
                    ?.find((carousel: any) => {
                      return carousel?.carousel === 'Live';
                    })
                    ?.episodes[0]?.subscriptions?.some((subscription: any) => {
                      return subscription?.isSubscribed;
                    }) || false
                );

                setLiveURL(
                  episodesList?.find((carousel: any) => {
                    return carousel?.carousel === 'Live';
                  })?.episodes[0]?.episodeStreamLink || ''
                );

                setMediaTitle(
                  episodesList?.find((carousel: any) => {
                    return carousel?.carousel === 'Live';
                  })?.episodes[0]?.episodeName || ''
                );

                // setLiveURL(
                //   episodesList.find((carousel: any) => {
                //     return carousel.carousel === 'Live';
                //   }).episodes[0].episodeStreamLink
                // );

                setLivePaymentUrl(
                  `/account/available-subscriptions/episode/${
                    response.streamLink[0].seasons[0].seasonID
                  }/${
                    episodesList?.find((carousel: any) => {
                      return carousel?.carousel === 'Live';
                    })?.episodes[0]?.episodeID
                  }`
                );
              });
          };

          getIsLiveAndSubscribed();

          if (response.streamLink[0].isAddedToMyList) {
            setWishlistBtnTxt('Remove from List');
            setWishlistBtnIcon('icon-favorite-filled');
          } else {
            setWishlistBtnTxt('Add to List');
            setWishlistBtnIcon('icon-favorite');
          }
        });
        break;
      default:
        console.log('Content type not found');
    }

    setIsMobile(window.innerWidth < 1000);

    const handleWindowResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleWindowResize);

    handleWindowResize();

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [params]);

  const goToDetail = () => {
    switch (contentType) {
      case 'movies':
        navigate(`/movies/${contentID}`);
        break;
      case 'tv-shows':
        navigate(`/tv-shows/${contentID}`);
        break;
      case 'sports':
        navigate(`/sports/${contentID}`);
        break;
      case 'explore':
        navigate(`/sports/${contentID}`);
        break;
      default:
        console.log('Invalid content type');
    }
  };

  // useEffect(() => {
  //   if (playerURL === '') {
  //     setLoadPlayer(false);
  //   } else {
  //     window.setTimeout(() => {
  //       setLoadPlayer(true);
  //     }, 2500);
  //   }
  // }, [playerURL]);

  const numberOfVideos = featuredCount;
  const containerWidth = isMobile ? '120vw' : '110vw';
  return (
    <>
      {
        <div
          className={clsx(stl.featuredCard)}
          // style={{width: `${numberOfVideos * 100}vw`}}
          style={{ width: containerWidth }}
        >
          {/*{<Loading show={loading}/>}*/}
          <div className={stl.featuredContentThumbnailContainer}>
            <LazyLoadImage
              className={clsx(stl.featuredContentThumbnail)}
              effect="blur"
              src={
                typeof contentImages !== 'undefined'
                  ? contentImages.imagePath
                  : ''
              }
              beforeLoad={() => {
                typeof contentImages !== 'undefined' && setLoading(true);
                typeof contentImages === 'undefined' && setLoading(false);
              }}
              onLoad={() => {
                setLoading(false);
              }}
            />
          </div>

          <div className={clsx(stl.featuredContent)}>
            <div className={clsx(stl.featuredContentDetails)}>
              {window.innerHeight > 800 && (
                <>
                  {/* <h1 className={clsx(stl.featuredContentTitle)}>
                    {contentOriginalTitle}
                  </h1> */}

                  {contentType != 'sports' && (
                    <ul className={clsx(stl.featuredContentMeta)}>
                      {contentDetails?.publishedYear !== undefined && (
                        <li className={clsx(stl.meta)}>
                          {contentDetails?.publishedYear}
                        </li>
                      )}
                      <li className={clsx(stl.meta)}>
                        {contentDetails?.genres}
                      </li>
                      <li className={clsx(stl.meta)}>
                        {contentDetails?.runtime}
                      </li>
                    </ul>
                  )}

                  <div className={clsx(stl.featuredContentDescription)}>
                    {contentDetails?.tagline}
                  </div>
                </>
              )}
            </div>

            <div className={clsx(stl.featuredContentButton)}>
              {/*{contentType != 'sports' && contentDetails.isSubscribed === true && (*/}
              {contentType != 'sports' && (
                <>
                  {typeof contentDetails !== 'undefined' &&
                    contentDetails.isSubscribed === true && (
                      <>
                        <BeginButton
                          className="primary"
                          datalabel={!isMobile ? 'Details' : ''}
                          type="button"
                          bold={true}
                          dataiconstart="icon-info"
                          squared={true}
                          tall={true}
                          iconOnly={isMobile}
                          onClick={goToDetail}
                        />
                        <BeginButton
                          className="white"
                          datalabel={!isMobile ? wishlistBtnTxt : ''}
                          type="button"
                          bold={true}
                          dataiconstart={wishlistBtnIcon}
                          squared={true}
                          tall={true}
                          loading={wishlistBtnLoading}
                          iconOnly={isMobile}
                          onClick={() => {
                            setWishlistBtnLoading(true);
                            if (wishlistBtnTxt === 'Remove from List') {
                              wishlistApi.removeFromWishList(
                                contentID,
                                (err, res) => {
                                  setWishlistBtnLoading(false);
                                  if (err) {
                                    console.log(err);
                                  } else {
                                    setWishlistBtnTxt('Add to List');
                                    setWishlistBtnIcon('icon-favorite');
                                  }
                                }
                              );
                            } else {
                              wishlistApi.addToWishList(
                                contentID,
                                contentType,
                                (err, res) => {
                                  setWishlistBtnLoading(false);
                                  if (err) {
                                    console.log(err);
                                  } else {
                                    setWishlistBtnTxt('Remove from List');
                                    setWishlistBtnIcon('icon-favorite-filled');
                                  }
                                }
                              );
                            }
                          }}
                        />
                      </>
                    )}

                  {contentDetails.isSubscribed === false && (
                    <BeginButton
                      className="primary"
                      datalabel="Subscribe Now"
                      to=""
                      type="button"
                      dataiconstart="icon-locked"
                      squared={true}
                      tall={true}
                      onClick={(event) => {
                        event.stopPropagation();
                        localStorage.setItem(
                          'lastLocation',
                          `/${contentType}/${contentID}`
                        );
                        navigate(
                          `/account/available-subscriptions/${contentType}/${contentID}`
                        );
                      }}
                    />
                  )}
                </>
              )}

              {/*{contentType === 'sports' && contentDetails.isSubscribed === true && (*/}
              {contentType === 'sports' && (
                <>
                  {hasLive === true && (
                    <>
                      <BeginButton
                        className="primary"
                        datalabel={!isMobile ? 'HIGHLIGHTS' : ''}
                        type="button"
                        bold={true}
                        dataiconstart="icon-video"
                        squared={true}
                        tall={true}
                        iconOnly={isMobile}
                        onClick={goToDetail}
                      />

                      {isSubscribed === true && (
                        <BeginButton
                          className="green"
                          datalabel={!isMobile ? 'Watch Live' : ''}
                          type="button"
                          bold={true}
                          dataiconstart="icon-play"
                          squared={true}
                          tall={true}
                          iconOnly={isMobile}
                          onClick={() => {
                            if (typeof setPlayerUrl !== 'undefined') {
                              setPlayerUrl(liveURL);
                            }

                            if (typeof setPlayerTitle !== 'undefined') {
                              setPlayerTitle(mediaTitle);
                            }

                            if (
                              typeof setPlayerMediaID !== 'undefined' &&
                              contentID ===
                                '9dfcf98b-6bea-4db5-b1c0-66398c454b00'
                            )
                              setPlayerMediaID('sMGqSfsO');
                          }}
                        />
                      )}

                      {isSubscribed === false && (
                        <BeginButton
                          className="white"
                          datalabel={!isMobile ? 'Subscribe to Watch Live' : ''}
                          to=""
                          type="button"
                          dataiconstart="icon-locked"
                          squared={true}
                          tall={true}
                          iconOnly={isMobile}
                          onClick={(event) => {
                            event.stopPropagation();
                            localStorage.setItem(
                              'lastLocation',
                              `/${contentType}/${contentID}`
                            );
                            navigate(livePaymentUrl);
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}

              {/*{contentType !== 'sports' && contentDetails.isSubscribed === false && (*/}
              {/*  <BeginButton*/}
              {/*    className="primary"*/}
              {/*    datalabel="Subscribe Now"*/}
              {/*    to=""*/}
              {/*    type="button"*/}
              {/*    dataiconstart="icon-locked"*/}
              {/*    squared={true}*/}
              {/*    tall={true}*/}
              {/*    onClick={(event) => {*/}
              {/*      event.stopPropagation();*/}
              {/*      localStorage.setItem(*/}
              {/*        'lastLocation',*/}
              {/*        `/${contentType}/${contentID}`*/}
              {/*      );*/}
              {/*      navigate(*/}
              {/*        `/account/available-subscriptions/${contentType}/${contentID}`*/}
              {/*      );*/}
              {/*    }}*/}
              {/*  />*/}
              {/*)}*/}
            </div>
          </div>
        </div>
      }

      {/* <div
        className={`${stl.playerContainer} ${
          playerURL === '' ? '' : stl.active
        }`}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <img
          src={logo}
          alt=""
          className={stl.playerLogo}
        />
        {playerURL && contentType != 'sports' && (
          <PlayerComponent
            playerEnabled={true}
            vodContent={true}
            playlist={[
              {
                file: playerURL,
              },
            ]}
          />
        )}

        {playerURL && contentType == 'sports' && loadPlayer === true && (
          <PlayerComponent
            playerEnabled={true}
            vodContent={true}
            playlist={[
              {
                file: playerURL,
              },
            ]}
          />
        )}

        <div className={stl.playerCloseButton}>
          <FaTimes
            onClick={() => {
              setPlayerURL('');
            }}
          />
        </div>
      </div> */}
    </>
  );
};

export default ContentFeaturedBanner;
