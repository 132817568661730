import React, { useEffect } from 'react';
import { getFirebasePermissions } from './firebase';
import { RouterProvider } from 'react-router-dom';

import Loading from './components/Loading/Loading';

import router from './utils/router';

import './assets/fonts/fontello/css/fontello.css';
import { DevicesApi } from './services/customer-devices';
import ReactGA from 'react-ga4';
import { GID } from './utils/google-containers';
import ReactPixel from 'react-facebook-pixel';

function App() {
  useEffect(() => {
    ReactGA.initialize(GID);
    const pixelID = '3339150763054070';
    ReactPixel.init(pixelID);

    console.log('navigator: ', navigator);
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    if (!isIOS) {
      getFirebasePermissions((token) => {
        DevicesApi.addDevice(token);
      });
    }
  }, []);
  return (
    <div>
      <RouterProvider router={router()} />
      <Loading show={false} />
    </div>
  );
}

export default App;
