import ContentCard from './content-card';

import React, {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import stl from './content-list.module.css';
import ReactSimplyCarousel from 'react-simply-carousel';
import ContentFeaturedBanner from './content-featured';

interface ContentListProps {
  priority: number;
  carousel: string;
  list: any;
  type: 'movies' | 'tv-shows' | 'sports';
  setPlayerUrl?: Dispatch<SetStateAction<string>>;
  setPlayerTitle?: Dispatch<SetStateAction<string>>;
  setPlayerMediaID?: Dispatch<SetStateAction<string>>;
}

const ContentList = ({
  priority,
  carousel,
  list,
  type,
  setPlayerUrl,
  setPlayerTitle,
  setPlayerMediaID,
}: ContentListProps) => {
  const [position, setPosition] = useState(0);
  const [contentList, setContentList] = useState<any[]>([]);
  const [featuredList, setFeaturedList] = useState<any[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [featuredCount, setFeaturedCount] = useState(0);

  useEffect(() => {
    setContentList(list);

    setIsMobile(window.innerWidth < 1000);

    const handleWindowResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [list]);

  useEffect(() => {
    let count = 0;
    const featuredContent: any[] = [];
    contentList.forEach((vod) => {
      if (
        (type === 'movies' && vod.movieTags?.includes('featured')) ||
        (type !== 'movies' && vod.seriesTags?.includes('featured'))
      ) {
        featuredContent.push(vod);
        count++;
      }
    });
    setFeaturedCount(count);
    setFeaturedList(featuredContent);
  }, [contentList, type]);

  // console.log(featuredList, type)

  const Featured = featuredList.map((vod: any, index: number) => {
    switch (type) {
      case 'movies':
        return (
          <ContentFeaturedBanner
            key={'movies-' + carousel + '-entry-' + index}
            contentType={type}
            contentOriginalTitle={vod.movieOriginalTitle}
            contentID={vod.movieID}
            contentImages={
              !isMobile
                ? vod.images.find((img: any) => img.imageType === 'cover-hd')
                : vod.images.find((img: any) => img.imageType === 'banner')
            }
            featuredCount={featuredCount}
          />
        );
      case 'tv-shows':
        return (
          <ContentFeaturedBanner
            key={'tv-shows-' + carousel + '-entry-' + Math.random()}
            contentType={type}
            contentOriginalTitle={vod.seriesOriginalTitle}
            contentID={vod.seriesID}
            contentImages={
              !isMobile
                ? vod.images.find((img: any) => img.imageType === 'cover-hd')
                : vod.images.find((img: any) => img.imageType === 'banner')
            }
            featuredCount={featuredCount}
          />
        );
      case 'sports':
        return (
          <ContentFeaturedBanner
            key={'sports-' + carousel + '-entry-' + Math.random()}
            contentType={type}
            contentOriginalTitle={vod.seriesOriginalTitle}
            contentID={vod.seriesID}
            contentImages={
              !isMobile
                ? vod.images.find((img: any) => img.imageType === 'cover-hd')
                : vod.images.find((img: any) => img.imageType === 'banner')
            }
            setPlayerUrl={setPlayerUrl}
            setPlayerTitle={setPlayerTitle}
            setPlayerMediaID={setPlayerMediaID}
            featuredCount={featuredCount}
          />
        );
      default:
        return '';
    }
  });

  // console.log(Featured, type)

  const Vods = list.map((vod: any, index: number) => {
    switch (type) {
      case 'movies':
        return (
          <ContentCard
            key={'movies-' + carousel + '-entry-' + Math.random()}
            contentType={type}
            contentOriginalTitle={vod.movieOriginalTitle}
            contentID={vod.movieID}
            contentImages={vod.images.find(
              (img: any) => img.imageType === 'poster'
            )}
            featuredCount={featuredCount}
          />
        );
      case 'tv-shows':
        return (
          <ContentCard
            key={'tv-shows-' + carousel + '-entry-' + Math.random()}
            contentType={type}
            contentOriginalTitle={vod.seriesOriginalTitle}
            contentID={vod.seriesID}
            contentImages={vod.images.find(
              (img: any) => img.imageType === 'poster'
            )}
            featuredCount={featuredCount}
          />
        );
      case 'sports':
        return (
          <ContentCard
            key={'sports-' + carousel + '-entry-' + Math.random()}
            contentType={type}
            contentOriginalTitle={vod.seriesOriginalTitle}
            contentID={vod.seriesID}
            contentImages={vod.images.find(
              (img: any) => img.imageType === 'poster'
            )}
            featuredCount={featuredCount}
          />
        );
      default:
        return '';
    }
  });

  return (
    <>
      {list && list.length > 0 && carousel === 'Featured' && (
        <div className={stl.featured}>
          <ReactSimplyCarousel
            activeSlideIndex={position}
            autoplay={true}
            autoplayDelay={5000}
            autoplayDirection={'forward'}
            onRequestChange={setPosition}
            itemsToShow={1}
            containerProps={{
              style: {
                width: '100vw',
              },
            }}
            itemsToScroll={1}
            forwardBtnProps={{
              className: `${stl.arrow} ${stl.arrowRight}`,
              children: <ArrowForwardIosIcon fontSize="small" />,
            }}
            backwardBtnProps={{
              className: `${stl.arrow} ${stl.arrowLeft}`,
              children: <ArrowBackIosIcon fontSize="small" />,
            }}
            // infinite={list.length * 320 > window.innerWidth ? true : false}
            infinite={true}
            speed={400}
            easing="linear"
          >
            {Featured}
          </ReactSimplyCarousel>
        </div>
      )}

      {list && list.length > 0 && carousel !== 'Featured' && (
        <div className={stl.contentListContainer}>
          <h1>{carousel}</h1>
          <ReactSimplyCarousel
            activeSlideIndex={position}
            onRequestChange={setPosition}
            itemsToShow={0}
            itemsToScroll={1}
            containerProps={{
              style: {
                width: '100vw',
                justifyContent: 'space-between',
                userSelect: 'none',
              },
            }}
            preventScrollOnSwipe
            swipeTreshold={60}
            forwardBtnProps={{
              className: `${stl.arrow} ${stl.arrowRight}`,
              children: <ArrowForwardIosIcon fontSize="small" />,
            }}
            backwardBtnProps={{
              className: `${stl.arrow} ${stl.arrowLeft}`,
              children: <ArrowBackIosIcon fontSize="small" />,
            }}
            speed={400}
            easing="linear"
            infinite={list.length * 224 > window.innerWidth ? true : false}
          >
            {Vods}
          </ReactSimplyCarousel>
        </div>
      )}
    </>
  );
};

export default memo(ContentList);
