import React, { FC, useEffect, useState } from 'react';
import { landingActions } from '../../../store/landing-slice';
import { useDispatch } from 'react-redux/es/exports';
import style from '../auth-child.module.css';
import ProgressDots from '../../../components/progress-dots/progress-dots';
import BeginInput from '../../../components/form-input-fields/begin-input';
import BeginButton from '../../../components/begin-button/begin-button';
import { useNavigate } from 'react-router-dom';

import { regex, errorMessages } from '../auth-child-constants';
import { forgotPassword } from '../../../services/Cognito';
import { ResetPasswordModel } from '../../../types/local-models/reset-password.component.model';
import { BeginToastModel } from '../../../types/local-models/begin-toast.component.model';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

const ForgotPassword: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.setTimeout(() => {
      dispatch(landingActions.childLoaded());
    }, 1500);
    ReactGA.event({
      category: 'Forgot Password',
      action: 'ForgotPassword_View',
      nonInteraction: false,
    });
    ReactPixel.track('ForgotPassword_View');
    dispatch(landingActions.hideLoader());
    dispatch(landingActions.hideToast());
  }, []);

  const [accountId, setAccountId] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [formError, setFormError] = useState('');

  const checkFormValidity = (setError = false) => {
    console.log(accountId);
    if (accountId.match(regex.accountId) != null) {
      setFormValid(true);
      setFormError('');
    } else {
      setFormValid(false);
      setFormError(errorMessages.accountId);
    }
  };

  const handleInputChange = (identifier: string, value: string) => {
    setAccountId(value);
    console.log(accountId);

    checkFormValidity();
  };

  const handleInputBlur = () => {
    checkFormValidity(true);
  };
  const logGAForgotCancel = () => {
    ReactGA.event({
      category: 'Landing Page',
      action: 'FPW_Cancel_Initiate',
      nonInteraction: false,
    });
    ReactPixel.track('FPW_Cancel_Initiate');
  };
  const navigate = useNavigate();
  const forgotPasswordSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(landingActions.showLoader());
    dispatch(landingActions.hideToast());

    forgotPassword(accountId, (err, result) => {
      ReactGA.event({
        category: 'Forgot Password',
        action: 'FPW_Next_Initiate',
        nonInteraction: false,
      });
      ReactPixel.track('FPW_Next_Initiate');
      if (err) {
        ReactGA.event({
          category: 'Forgot Password',
          action: 'FPW_Next_Failed',
          nonInteraction: false,
        });
        ReactPixel.track('FPW_Next_Failed');
        const toastError: BeginToastModel = {
          type: 'error',
          shown: true,
          text: err,
          dismissduration: 5000,
        };
        dispatch(landingActions.showToast(toastError));
        dispatch(landingActions.hideLoader());
        return;
      } else {
        const otpScreen: ResetPasswordModel = {
          account: accountId,
          serverResponse: result,
        };
        ReactGA.event({
          category: 'Forgot Password',
          action: 'FPW_Next_Success',
          nonInteraction: false,
        });
        ReactPixel.track('FPW_Next_Success');
        dispatch(landingActions.resetPasswordOTP(otpScreen));

        navigate('/account/reset-password');
      }
    });
  };

  return (
    <div className={style.authContainer}>
      <form onSubmit={forgotPasswordSubmit} className={style.form}>
        <div className={style.authContent}>
          <h1 className="title">Forgot Password</h1>

          <div className={style.group}>
            <ProgressDots dots={2} activeIndex={0} />
            <div className="preHeading">{"Don't worry, we got you"}</div>
            <h1></h1>
            <div className={style.titleLabel}>
              Enter your email address to receive an OTP
            </div>
            <div className={style.separator}></div>
          </div>

          <div className={style.group}>
            <BeginInput
              type="text"
              placeholder="Email"
              name="account_id"
              id="account_id"
              required={true}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange('accountId', event?.target?.value)
              }
              onBlur={() => handleInputBlur()}
              value={accountId}
              error_message={formError}
            />
          </div>

          <div className={style.group}>
            <div className={style.err}>{}</div>

            <BeginButton
              type="submit"
              datalabel="Next"
              className="primary"
              to=""
              disabled={!formValid}
            />

            <BeginButton
              preset="link"
              type="button"
              className="cancel"
              to="/account/"
              datalabel="Cancel"
              dataiconstart="icon-close"
              onClick={logGAForgotCancel}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
