import React, { useState } from 'react';
import clsx from 'clsx';

import { memo, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './content-list.module.css';

import { ContentCardProps } from '../../types/local-models/content-card.model';
import { MoviesApi } from '../../services/content-apis/movies';
import BeginButton from '../begin-button/begin-button';
import { tvShowsApi } from '../../services/content-apis/tvShows';
import { Navigate, useNavigate } from 'react-router-dom';
import { sportsApi } from '../../services/content-apis/sports';
import { wishlistApi } from '../../services/content-apis/wishlist';

const ContentCard = ({
  contentID: initialContentID,
  contentType: initialContentType,
  contentURL,
  contentOriginalTitle,
  featuredCount,
  onClick,
  contentImages,
}: ContentCardProps) => {
  const [loading, setLoading] = useState(false);
  const [contentDetails, setContentDetails] = useState<any>({});
  const [contentID, setContentID] = useState(initialContentID || '');
  const [contentType, setContentType] = useState(initialContentType || '');

  const [isSubscribed, setIsSubscribed] = useState(false);

  const [wishlistBtnTxt, setWishlistBtnTxt] = useState('Add to List');
  const [wishlistBtnIcon, setWishlistBtnIcon] = useState('icon-favorite');
  const [wishlistBtnLoading, setWishlistBtnLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!initialContentID || !initialContentType) return;
    setContentID(initialContentID);
    setContentType(initialContentType);
  }, [initialContentID, initialContentType]);

  const goToDetail = () => {
    console.log('content type', contentType);
    switch (contentType) {
      case 'movies':
        navigate(`/movies/${contentID}`);
        break;
      case 'tv-shows':
        navigate(`/tv-shows/${contentID}`);
        break;
      case 'sports':
        navigate(`/sports/${contentID}`);
        break;
      // case 'explore':
      //   navigate(`/sports/${contentID}`);
      //   break;
      default:
        console.log('Invalid content type');
    }
  };

  const Loading = loading && (
    <div className={clsx(styles.cardFallback)}>{contentOriginalTitle}</div>
  );

  const handlePlayButtonClick = () => {
    return <Navigate to={`/movies/user-home/${contentType}/${contentID}`} />;
  };

  const getDetails = () => {
    switch (contentType) {
      case 'movies':
        MoviesApi.getMovie(contentID, 'card').then((response: any) => {
          setIsSubscribed(response.isSubscribed);
          setContentDetails(response);

          if (
            typeof response !== 'undefined' &&
            typeof response.streamLink !== 'undefined'
          )
            return;
          if (response.streamLink[0].isAddedToMyList) {
            setWishlistBtnTxt('Remove from List');
            setWishlistBtnIcon('icon-favorite-filled');
          }
        });
        break;
      case 'tv-shows':
        tvShowsApi.getTVShow(contentID, 'card').then((response) => {
          if (response) {
            setIsSubscribed(response.isSubscribed);
            setContentDetails(response);

            if (response.streamLink[0].isAddedToMyList) {
              setWishlistBtnTxt('Remove from List');
              setWishlistBtnIcon('icon-favorite-filled');
            }
          }
        });
        break;
      case 'sports':
        sportsApi.getTVShow(contentID, 'card').then((response) => {
          if (typeof response !== 'undefined' && response !== null) {
            setIsSubscribed(response.isSubscribed);
            setContentDetails(response);

            if (response.streamLink[0].isAddedToMyList) {
              setWishlistBtnTxt('Remove from List');
              setWishlistBtnIcon('icon-favorite-filled');
            }
          }
        });
        break;
      default:
        console.log('Content type not found');
    }
  };

  const truncateTagLine = (tagline: string) => {
    if (contentType === 'sports' && tagline.length > 150) {
      return tagline.substring(0, 150) + '...';
    } else if (
      contentType !== 'sports' &&
      !Array.isArray(tagline) &&
      tagline.length >= 100
    ) {
      return tagline.substring(0, 100) + '...';
    } else if (
      contentType !== 'sports' &&
      Array.isArray(tagline) &&
      tagline[0].length >= 100
    ) {
      return tagline[0].substring(0, 100) + '...';
    }
    return tagline;
  };

  return (
    <>
      {
        <div
          className={clsx(styles.card)}
          onMouseEnter={() => {
            getDetails();
          }}
          onMouseLeave={() => {
            // setContentDetails({});
          }}
          onClick={goToDetail}
        >
          {Loading}
          {typeof contentImages !== 'undefined' &&
            typeof contentImages.imagePath !== 'undefined' && (
              <LazyLoadImage
                className={clsx(styles.contentThumbnail)}
                width={'20.74vh'}
                height={'29.35vh'}
                effect="blur"
                src={contentImages.imagePath}
                beforeLoad={() => {
                  setLoading(true);
                }}
                onLoad={() => {
                  setLoading(false);
                }}
              />
            )}

          <div
            className={`${styles.contentDetails} ${
              Object.keys(contentDetails).length == 0
                ? styles.contentDetailsLoading
                : ''
            }`}
          >
            {Object.keys(contentDetails).length > 0 && (
              <>
                <h1 className={styles.contentTitle}>{contentOriginalTitle}</h1>

                {contentType !== 'sports' && (
                  <ul className={styles.cardContentMeta}>
                    <li className={clsx(styles.meta)}>
                      {contentDetails.publishedYear}
                    </li>
                    <li className={clsx(styles.meta)}>
                      {contentDetails.genres}
                    </li>
                    <li className={clsx(styles.meta)}>
                      {contentDetails.runtime}
                    </li>
                  </ul>
                )}

                <div className={clsx(styles.contentDescription)}>
                  {truncateTagLine(contentDetails.tagline)}
                </div>

                <div className={clsx(styles.contentButton)}>
                  {isSubscribed === true && (
                    <>
                      <BeginButton
                        className="primary"
                        datalabel="Details"
                        type="button"
                        dataiconstart="icon-info"
                        squared={true}
                      />
                      <BeginButton
                        className="white"
                        datalabel={wishlistBtnTxt}
                        type="button"
                        dataiconstart={wishlistBtnIcon}
                        squared={true}
                        loading={wishlistBtnLoading}
                        onClick={(event) => {
                          event.stopPropagation();

                          setWishlistBtnLoading(true);
                          if (wishlistBtnTxt === 'Remove from List') {
                            wishlistApi.removeFromWishList(
                              contentID,
                              (err, res) => {
                                setWishlistBtnLoading(false);
                                if (err) {
                                  console.log(err);
                                } else {
                                  setWishlistBtnTxt('Add to List');
                                  setWishlistBtnIcon('icon-favorite');
                                }
                              }
                            );
                          } else {
                            wishlistApi.addToWishList(
                              contentID,
                              contentType,
                              (err, res) => {
                                setWishlistBtnLoading(false);
                                if (err) {
                                  console.log(err);
                                } else {
                                  setWishlistBtnTxt('Remove from List');
                                  setWishlistBtnIcon('icon-favorite-filled');
                                }
                              }
                            );
                          }
                        }}
                      />
                    </>
                  )}

                  {!isSubscribed && (
                    <BeginButton
                      className="primary"
                      datalabel="Subscribe Now"
                      to=""
                      type="button"
                      dataiconstart="icon-locked"
                      squared={true}
                      onClick={(event) => {
                        event.stopPropagation();
                        navigate(
                          `/account/available-subscriptions/${contentType}/${contentID}`
                        );
                      }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      }
    </>
  );
};

export default memo(ContentCard);
