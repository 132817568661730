import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './MobileLogin.module.css';
import { getUserByIDToken } from '../../services/Cognito';

enum Status {
  LOADING = 0,
  SUCCESS = 1,
  ERROR = -1,
}

const MobileLogin = () => {
  const params = useParams();
  const [error, setError] = useState('');
  const [status, setStatus] = useState(Status.LOADING);

  useEffect(() => {
    const idToken = params.idtoken || '';

    if (idToken !== '') {
      getUserByIDToken(idToken).then((response) => {
        if (typeof response === 'string') {
          setError(response);
          setStatus(Status.ERROR);
        } else {
          setStatus(Status.SUCCESS);
        }
      });
    }
  }, [params]);

  return (
    <div className={styles.container}>
      {status === Status.LOADING && <div>Loading...</div>}
      {status === Status.SUCCESS && (
        <div>
          Success, Continuing with the Subscription Flow...
          <a href="beginwatchapp://">Back to the app...</a>
        </div>
      )}
      {status === Status.ERROR && <div>Error: {error}</div>}
    </div>
  );
};

export default MobileLogin;
