import React, { useState } from 'react';
import BeginInput from '../../../../components/form-input-fields/begin-input';
import BeginButton from '../../../../components/begin-button/begin-button';

import styles from '../../SettingsModules.module.css';
import { updatePassword } from '../../../../services/Cognito';

import { regex, errorMessages } from '../../../Auth/auth-child-constants';
import Loading from '../../../../components/Loading/Loading';
import BeginToast from '../../../../components/begin-toast/begin-toast';

import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';

const Password: React.FC = () => {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [submitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  const confirmPassword = () => {
    const confirm_password = document.getElementById(
      'confirmNewPassword'
    ) as HTMLInputElement;

    if (newPassword != confirmNewPassword) {
      confirm_password.setCustomValidity("Passwords Don't Match");
    } else {
      confirm_password.setCustomValidity('');
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const form = document.getElementById('form') as HTMLFormElement;

    console.log('form', form);

    if (form?.checkValidity()) {
      setSubmitted(true);

      updatePassword(password, newPassword, (err, result) => {
        setSubmitted(false);

        if (err) {
          setError(err.message);

          setTimeout(() => {
            setError('');
          }, 5000);
        } else {
          setError('');
          setSuccess('Password updated successfully');
        }
      });
    }
  };

  return (
    <>
      <div
        className={styles.back}
        onClick={() => navigate(-1)}
      >
        <FaChevronLeft />
      </div>
      <Loading show={submitted} />
      <div className={styles.childModule}>
        <h2>Update Password</h2>
        <form
          onSubmit={handleSubmit}
          id="form"
        >
          <label className={styles.formInput}>
            Current Password:
            <BeginInput
              type="password"
              placeholder="Password"
              value={password}
              id="password"
              name="password"
              required={true}
              pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}"
              autoComplete="current-password"
              onChange={(event) => setPassword(event.target.value)}
            />
          </label>

          <label className={styles.formInput}>
            New Password:
            <BeginInput
              type="password"
              placeholder="Password"
              value={newPassword}
              id="newPassword"
              name="newPassword"
              required={true}
              pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}"
              autoComplete="new-password"
              onChange={(event) => setNewPassword(event.target.value)}
              onKeyUp={(event) => {
                setNewPassword((event.target as HTMLInputElement).value);
                confirmPassword();
              }}
            />
          </label>

          <label className={styles.formInput}>
            Confirm New Password:
            <BeginInput
              type="password"
              placeholder="Password"
              value={confirmNewPassword}
              id="confirmNewPassword"
              name="confirmNewPassword"
              required={true}
              pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}"
              autoComplete="confirm-new-password"
              onChange={(event) => setConfirmNewPassword(event.target.value)}
              onKeyUp={(event) => {
                setConfirmNewPassword((event.target as HTMLInputElement).value);
                confirmPassword();
              }}
            />
          </label>

          <BeginButton
            type="submit"
            datalabel="Submit"
            className="primary"
            to=""
          />
        </form>

        <div
          onClick={() => {
            setError('');
          }}
        >
          <BeginToast
            text={error}
            type="error"
            shown={error !== '' && error !== null ? true : false}
            dismissduration={5000}
          />
        </div>

        <div
          onClick={() => {
            setSuccess('');
          }}
        >
          <BeginToast
            text={success}
            type="success"
            shown={success !== '' && success !== null ? true : false}
            dismissduration={5000}
          />
        </div>
      </div>
    </>
  );
};

export default Password;
