import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfileAPI } from '../../../services/profile';

import styles from './Profile.module.css';
import Loading from '../../../components/Loading/Loading';
import AddEditProfile from './AddEditProfile';

import { FaTrash, FaPencilAlt, FaTimes } from 'react-icons/fa';
import DeleteProfile from './DeleteProfile';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

interface ProfileProps {
  type: 'load' | 'select' | 'delete';
  addProfile?: boolean;
  editProfile?: string;
  deleteProfile?: string;
}

const Profile: React.FC<ProfileProps> = ({
  type,
  addProfile,
  editProfile,
  deleteProfile,
}: ProfileProps) => {
  const navigate = useNavigate();

  const [profiles, setProfiles] = React.useState<any>({});
  const [profilesLoading, setProfilesLoading] = React.useState(false);

  const [shouldUpdate, setShouldUpdate] = React.useState(false);

  React.useEffect(() => {
    setShouldUpdate(false);
    setProfilesLoading(true);
    ReactGA.event({
      category: 'Profiles',
      action: 'Profiles_View',
      nonInteraction: false,
    });
    ReactPixel.track('Profiles_View');
    ProfileAPI.getAll().then((response) => {
      setProfilesLoading(false);
      setProfiles(response);

      if (type === 'load') {
        if (response.length === 0) {
          ProfileAPI.add({
            name: localStorage.getItem('accountID'),
            avatar:
              'https://s3.ap-southeast-1.amazonaws.com/begin.assets/avatars/avatar_01.png',
            forKids: false,
            type: 'default',
          }).then((createProfileResponse) => {
            localStorage.setItem(
              'profile',
              JSON.stringify(createProfileResponse.attributes)
            );
            navigate('/sports');
          });
        } else if (response.length === 1) {
          localStorage.setItem('profile', JSON.stringify(response[0]));
          localStorage.setItem('profileID', response[0].acctProfileID);
          navigate('/sports');
        }
      }
    });
  }, [shouldUpdate]);
  const logGAProfileInitiate = () => {
    ReactGA.event({
      category: 'Profiles',
      action: 'Prof_Select_Initiate',
      nonInteraction: false,
    });
    ReactPixel.track('Prof_Select_Initiate');
    ReactGA.event({
      category: 'Profiles',
      action: 'Prof_Select_Success',
      nonInteraction: false,
    });
    ReactPixel.track('Prof_Select_Success');
  };

  const logGAprofileAdd = () => {
    ReactGA.event({
      category: 'Profiles',
      action: 'Prof_Add_Initiate',
      nonInteraction: false,
    });
    ReactPixel.track('Prof_Add_Initiate');
  };
  const handleEditIconClick = (event: any, profile: any) => {
    event.stopPropagation();
    navigate('/profiles/edit-profile/' + profile.acctProfileID);
    ReactGA.event({
      category: 'Profiles',
      action: 'Prof_EPSave_Initiate',
      nonInteraction: false,
    });
    ReactPixel.track('Prof_EPSave_Initiate');
  };

  const handleDeleteIconClick = (event: any, profile: any) => {
    event.stopPropagation();
    navigate('/profiles/delete-profile/' + profile.acctProfileID);
  };

  const selectProfile = (profile: any) => {
    localStorage.setItem('profile', JSON.stringify(profile));
    localStorage.setItem('profileID', profile.acctProfileID);
    navigate('/sports');
  };

  return (
    <div className={styles.profileContainer}>
      {profiles.length == 0 && <Loading show={true} />}
      {profiles.length >= 1 && (
        <>
          <Loading show={profilesLoading} />
          <h1 className={styles.mainHeading}>
            {type === 'load' ? "Who's Watching" : 'Profiles'}
          </h1>
          <ul className={styles.profileList}>
            {profiles.map((profile: any) => (
              <li
                key={profile.acctProfileID + '-profile'}
                className={styles.profileListItem}
                onClick={() => selectProfile(profile)}
              >
                <div
                  className={styles.profileImage}
                  onClick={logGAProfileInitiate}
                >
                  <div>
                    <img
                      key={profile.acctProfileID + '-profileAvatar'}
                      src={profile.acctProfileAvatar}
                      alt={profile.name}
                      className={styles.profileImage}
                    />
                    <div className={styles.profileActions}>
                      {profile.acctProfileType != 'default' && (
                        <div
                          className={`${styles.profileActionIcon} ${styles.deleteIcon}`}
                          onClick={(event) =>
                            handleDeleteIconClick(event, profile)
                          }
                        >
                          <i className="icon-delete" />
                        </div>
                      )}
                      <div
                        className={`${styles.profileActionIcon} ${styles.editIcon}`}
                        onClick={(event) => handleEditIconClick(event, profile)}
                      >
                        {/* <FaPencilAlt /> */}
                        <i className="icon-edit" />
                      </div>
                    </div>
                  </div>
                </div>

                <p>{profile.acctProfileName}</p>
              </li>
            ))}

            {profiles.length < 5 && (
              <li
                className={`${styles.profileListItem}`}
                key="addProfile"
                onClick={() => {
                  navigate('/profiles/add-profile');
                  logGAprofileAdd();
                }}
              >
                <div className={`${styles.profileImage} ${styles.addProfile}`}>
                  +
                </div>
                <p>Add Profile</p>
              </li>
            )}
          </ul>

          <div
            className={`${styles.overlay} ${
              addProfile ||
              typeof editProfile == 'string' ||
              typeof deleteProfile == 'string'
                ? styles.showOverlay
                : ''
            }`}
          ></div>

          <aside
            className={`${styles.sidebar} ${
              addProfile ? styles.showSidebar : ''
            }`}
          >
            {addProfile && (
              <AddEditProfile type="add" setShouldUpdate={setShouldUpdate} />
            )}
          </aside>

          <aside
            className={`${styles.sidebar} ${
              typeof editProfile == 'string' ? styles.showSidebar : ''
            }`}
          >
            {typeof editProfile == 'string' && (
              <AddEditProfile type="edit" setShouldUpdate={setShouldUpdate} />
            )}
          </aside>

          <aside
            className={`${styles.sidebar} ${
              typeof deleteProfile == 'string' ? styles.showSidebar : ''
            }`}
          >
            {typeof deleteProfile == 'string' && (
              <DeleteProfile setShouldUpdate={setShouldUpdate} />
            )}
          </aside>

          {type !== 'load' && (
            <div className={styles.closeIconContainer}>
              <FaTimes
                onClick={() =>
                  navigate(localStorage.getItem('lastLocation') || '/')
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Profile;
