import React, { useEffect, useState } from 'react';
import style from './partials.module.css';
import { SubscriptionsAPI } from '../../../services/subscriptions';
import BeginButton from '../../../components/begin-button/begin-button';

import { useNavigate } from 'react-router-dom';

import { FaCheckSquare } from 'react-icons/fa';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

const MySubscriptions: React.FC = () => {
  const [allSubscriptions, setAllSubscriptions] = useState<any>([]);
  const [mySubscriptions, setMySubscriptions] = useState<any>([]);

  const navigate = useNavigate();
  useEffect(() => {
    ReactGA.event({
      category: 'Settings-Subscriptions',
      action: 'SS_View',
      nonInteraction: false,
    });
    ReactPixel.track('SS_View');
  });
  useEffect(() => {
    SubscriptionsAPI.getAllCustomerSubscriptions().then((response) => {
      setMySubscriptions(response);

      console.log('mySubscriptions', response);

      SubscriptionsAPI.getAll(null).then((responseAllSubs): any => {
        let count = 0;
        const temp: any[] = [];

        if (Array.isArray(responseAllSubs)) {
          responseAllSubs.forEach((element: any) => {
            console.log(responseAllSubs);
            if (
              !response.find(
                (sub: any) => sub.subscriptionID === element.subscriptionID
              )
            ) {
              temp.push(element);
            }

            if (count == responseAllSubs.length - 1) {
              setAllSubscriptions(temp);
            }

            count++;
          });
        }
      });
    });
  }, []);

  const subscribe = (subscription: any) => {
    ReactGA.event({
      category: 'Settings-Subscriptions',
      action: 'SS_PackageSelect_Initiate',
      nonInteraction: false,
    });
    ReactPixel.track('SS_PackageSelect_Initiate');
    localStorage.setItem('subscriptionsListLocal', allSubscriptions);
    localStorage.setItem(
      'selectedSubscriptions',
      JSON.stringify(subscription.subscriptionTitle)
    );
    localStorage.setItem(
      'subscriptionDurations',
      JSON.stringify(subscription.subscriptionPricing)
    );
    localStorage.setItem('selectedSubscriptionID', subscription.subscriptionID);

    if (subscription.subscriptionPricing.length > 1) {
      navigate('/account/select-subscription-duration');
    } else {
      localStorage.setItem(
        'selectedBillingCycle',
        subscription.subscriptionPricing[0].type
      );
      navigate('/account/payment-methods');
    }
  };

  return (
    <section
      id="my-subscription"
      className={`${style.section} ${style.subscriptionSection}`}
    >
      {Array.isArray(mySubscriptions) && mySubscriptions.length > 0 && (
        <div>
          <h1>My Subscriptions</h1>

          <div className={style.subscriptionsContainer}>
            {mySubscriptions.map((subscription: any) => (
              <div
                className={style.subscriptionCard}
                key={`my-sub-${subscription.subscriptionID}`}
              >
                <h2>{subscription.subscriptionTitle}</h2>
                <div className={style.price}>
                  {subscription.subscriptionPricing[0].price} PKR
                </div>
                <div className={style.subscribed}>
                  <FaCheckSquare />
                  <span>Subscribed</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {Array.isArray(allSubscriptions) && allSubscriptions.length > 0 && (
        <div>
          <h1>All Subscriptions</h1>

          <div
            className={`${style.subscriptionsContainer} ${style.newSubscriptions}`}
          >
            {allSubscriptions.map((subscription: any) => (
              <div
                className={style.subscriptionCard}
                key={`sub-${subscription.subscriptionID}`}
              >
                <h2>{subscription.subscriptionTitle}</h2>

                <div className={style.price}>
                  <span className={style.priceSpan}>Starting from</span>
                  <br />
                  {subscription.subscriptionPricing[0].price} PKR
                </div>

                <BeginButton
                  datalabel="Subscribe"
                  className="primary"
                  preset="button"
                  type="button"
                  onClick={() => {
                    subscribe(subscription);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default MySubscriptions;
